import '@babel/polyfill/noConflict';
import React from 'react';
import ReactDOM from 'react-dom';
import './sass/index.scss';
import App from './App';

ReactDOM.render(
  <div>
    <App />
  </div>,
  document.getElementById('kubra-chat'),
);
/* end default store and render */

