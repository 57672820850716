import PropTypes from 'prop-types';
import React from 'react';

export const LoaderComponent = ({
  loader,
}) => (
  <div className="loader-holder">
    {loader
      && (
      <div className="kubra-chat-loader">
        <div />
        <div />
        <div />
      </div>
      )
    }
  </div>
);

{
  const {
    bool,
  } = PropTypes;

  LoaderComponent.propTypes = {
    loader: bool.isRequired,
  };
}

export default LoaderComponent;
