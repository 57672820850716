import React from 'react';
import PropTypes from 'prop-types';

export const ChatCTA = (props) => {
  const {
    chatWindowOpen,
    ctaPosition,
    backgroundColor,
    stylePosition,
    title,
    toggleChatWindow,
  } = props;

  const styleObj = { ...backgroundColor, ...stylePosition };

  return (
    <div>
      { !chatWindowOpen && (
      <div
        role="presentation"
        onClick={() => toggleChatWindow()}
        className={`kubra-chat-cta ${Object.entries(stylePosition).length > 0 ? '' : ctaPosition}`}
        style={styleObj}
      >
        <p>{title}</p>
      </div>
      )}
    </div>
  );
};

{
  const {
    shape, bool, func, string,
  } = PropTypes;

  ChatCTA.propTypes = {
    backgroundColor: shape({}).isRequired,
    chatWindowOpen: bool.isRequired,
    ctaPosition: string.isRequired,
    stylePosition: shape({}).isRequired,
    title: string.isRequired,
    toggleChatWindow: func.isRequired,
  };
}

export default ChatCTA;
