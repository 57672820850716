import _ from 'lodash';
import config from './default';
import origin from '../lib/origin';

const BASE_URL = origin();

const test = _.cloneDeep(config);

test.googleAnalyticsCode = 'UA-110840836-2';

const authConfig = {
  ...test.auth,
  silentCallbackPath: `${BASE_URL}/boilerplate/silent-callback`,
  callbackPath: `${BASE_URL}/boilerplate/callback`,
  logoutPath: `${BASE_URL}/boilerplate/logout`,
};

test.auth = authConfig;

export default test;
