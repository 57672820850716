import axios from 'axios';
import Promise from 'bluebird';
import joinRequestParameters from 'kubra-lib-common-js/dist/joinRequestParameters';

export default function chatApi(
  {
    host, useMock, tapCallback = () => {}, routes: _routes,
  },
) {
  const routes = _routes(host);

  function log(response) {
    tapCallback(response.data);
    return Promise.resolve(response);
  }

  function auth(promiseCallback) {
    const options = {
      auth: {
        username: 'support2+iq-dialogflow@kubra.com',
        password: 'Bm25v9SqXZPd4q=x',
      },
      headers: {
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
      },
    };
    return promiseCallback(options);
  }

  function postItem(data) {
    return auth(options => axios.post(`${routes.chat.route}`, data, options))
      .then(log)
      .then(response => response.data)
      .catch(err => console.log(err.response.data));
  }

  function getItemByIdWithoutAuth({ parameters }) {
    const requestParameters = joinRequestParameters(parameters);
    if (useMock) {
      return Promise.resolve(routes.items.mocks.get.one).then(log);
    }

    return auth(options => axios.post(`${routes.items.route}${requestParameters}`,
      { headers: { ...options.headers } }))
      .then(log)
      .then(response => response.data);
  }

  return {
    postItem,
    getItemByIdWithoutAuth,
  };
}
