import PropTypes from 'prop-types';
import React from 'react';

export const PrivacyScreen = ({
  privacyMessage,
  privacySupportText,
  buttonHoverStyle,
  setButtonHovered,
  privateConfirmClicked,
}) => (
  <div className="kubra-chat-private-notification">
    <div>{privacyMessage}</div>
    <div className="subtext">
      {privacySupportText}
    </div>
    <div>
      <button
        type="button"
        className="kubra-btn kubra-yes-btn"
        onClick={() => privateConfirmClicked(true)}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={buttonHoverStyle}
      >
        Yes
      </button>
    </div>
    <div>
      <button
        type="button"
        className="kubra-btn kubra-no-btn"
        onClick={() => privateConfirmClicked(false)}
      >
        No
      </button>
    </div>
  </div>
);

{
  const {
    string,
    func,
    shape,
  } = PropTypes;

  PrivacyScreen.propTypes = {
    buttonHoverStyle: shape({}).isRequired,
    privateConfirmClicked: func.isRequired,
    privacyMessage: string.isRequired,
    privacySupportText: string.isRequired,
    setButtonHovered: func.isRequired,
  };
}

export default PrivacyScreen;
