import origin from '../lib/origin';

const BASE_URL = origin();

const api = {
  chatApi: {
    host: `${BASE_URL}/iq/v1`,
    useMocks: false,
  },
};

const authConfig = {
  clientId: 'PcggvMMnn7EzE9POSiz9CN4XtibvBfD2',
  origin: 'auth0.kubra.io',
  audience: 'https://kubra.io/api',
  silentCallbackPath: `${BASE_URL}/login/silent-callback`,
  callbackPath: `${BASE_URL}/login/callback`,
  logoutPath: `${BASE_URL}/login/logout`,
  responseType: 'token id_token',
  scope: 'openid profile name email',
  useCookies: false,
};

export default {
  api,
  auth: authConfig,
};
