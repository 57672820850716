import _ from 'lodash';
import config from './default';
import origin from '../lib/origin';

const BASE_URL = origin();

const dev = _.cloneDeep(config);

const authConfig = {
  ...dev.auth,
  silentCallbackPath: `${BASE_URL}/boilerplate/silent-callback`,
  callbackPath: `${BASE_URL}/boilerplate/callback`,
  logoutPath: `${BASE_URL}/boilerplate/logout`,
};

dev.auth = authConfig;

dev.api.chatApi.host = 'https://dev.kubra.io/iq/v1';

export default dev;
