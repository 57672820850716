import PropTypes from 'prop-types';
import React from 'react';

export const InputArea = ({
  backgroundStyle,
  chatRef,
  fieldValue,
  fieldValuePot,
  inputChange,
  inputChangePot,
  keyPressChange,
  send,
}) => (
  <div className="kubra-input-area">
    <label
      htmlFor="chatField0427"
      name="chatLabel3325"
    >
      <span>Chat Text Input</span>
      <textarea
        autoComplete="off"
        data-locator="chatField"
        id="chatField0427"
        name="chatField0427"
        onChange={e => inputChange(e)}
        onKeyPress={e => keyPressChange(e)}
        placeholder="Type Message Here..."
        ref={chatRef}
        rows={2}
        value={fieldValue}
        tabIndex={-1}
      />
    </label>
    {/* this is a honeypot field for spambots */}
    <label
      htmlFor="chatFieldPot"
      name="chatLabelPot"
      id="chatFieldPotLabel"
    >
      <span>Chat Text Input Next</span>
      <input
        type="text"
        id="chatFieldPot"
        name="password_new"
        onChange={event => inputChangePot(event)}
        placeholder="Type Message Here..."
        value={fieldValuePot}
        autoComplete="false"
        tabIndex="-1"
      />
    </label>
    <button
      className="kubra-send-button"
      onClick={() => send()}
      style={backgroundStyle}
      type="button"
      tabIndex={0}
    >
      <span className="adaText">Send Button</span>
      <span className="icon iconic" data-glyph="share" />
    </button>
  </div>
);

{
  const {
    string, func, shape,
  } = PropTypes;

  InputArea.propTypes = {
    backgroundStyle: shape({}).isRequired,
    chatRef: shape({}).isRequired,
    fieldValue: string.isRequired,
    fieldValuePot: string.isRequired,
    inputChange: func.isRequired,
    inputChangePot: func.isRequired,
    keyPressChange: func.isRequired,
    send: func.isRequired,
  };
}

export default InputArea;
