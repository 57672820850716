import PropTypes from 'prop-types';
import React from 'react';

export const ChatHeader = ({
  backgroundColor,
  chatWinMin,
  title,
  toggleScroll,
  toggleChatWindow,
}) => (
  <div
    className={`kubra-chat-header ${chatWinMin ? 'clickable' : ''}`}
    onClick={e => toggleScroll(e)}
    role="presentation"
    style={backgroundColor}
  >
    {title}
    <button
      className="kubra-close-button float-right"
      onClick={() => toggleChatWindow()}
      style={backgroundColor}
      type="button"
    >
      <span className="adaText">Close Button</span>
      <span className="icon iconic" data-glyph="x" />
    </button>
    { !chatWinMin
      && (
      <button
        className="kubra-min-button float-right chatToggle"
        onClick={e => toggleScroll(e)}
        style={backgroundColor}
        type="button"
      >
        <span className="adaText">Minimize Button</span>
        <span className="chatToggle icon iconic" data-glyph="minus" />
      </button>
      )}
  </div>
);

{
  const {
    string, func, shape, bool,
  } = PropTypes;

  ChatHeader.propTypes = {
    backgroundColor: shape({}).isRequired,
    chatWinMin: bool.isRequired,
    title: string.isRequired,
    toggleChatWindow: func.isRequired,
    toggleScroll: func.isRequired,
  };
}

export default ChatHeader;
