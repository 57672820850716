import PropTypes from 'prop-types';
import React from 'react';

export const NameScreen = ({
  buttonHoverStyle,
  nameError,
  nameField,
  nameKeyPressEvent,
  nameMessage,
  nameSupportText,
  onNameChangeEvent,
  nameChatClicked,
  setButtonHovered,
  toggleChatWindow,
}) => (
  <div className="kubra-chat-private-notification">
    <div>{nameMessage}</div>
    <div className="subtext">
      {nameSupportText}
    </div>
    <div>
      <input
        data-locator="nameField"
        name="nameField"
        id="nameField"
        htmlFor="nameLabel"
        className="nameField"
        placeholder="Enter Name"
        value={nameField}
        onKeyPress={event => nameKeyPressEvent(event)}
        onChange={event => onNameChangeEvent(event)}
        autoComplete="off"
      />
    </div>
    <div className="kubra-error">
      {nameError}
    </div>
    <div>
      <button
        type="button"
        className="kubra-btn kubra-chat-btn"
        onClick={() => nameChatClicked()}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={buttonHoverStyle}
      >
        Chat
      </button>
    </div>
    <div>
      <button
        type="button"
        className="kubra-btn kubra-cancel-btn"
        onClick={() => toggleChatWindow()}
      >
        Cancel
      </button>
    </div>
  </div>
);

{
  const {
    string, func, shape,
  } = PropTypes;

  NameScreen.propTypes = {
    buttonHoverStyle: shape({}).isRequired,
    nameError: string.isRequired,
    nameField: string.isRequired,
    nameKeyPressEvent: func.isRequired,
    nameMessage: string.isRequired,
    nameSupportText: string.isRequired,
    onNameChangeEvent: func.isRequired,
    nameChatClicked: func.isRequired,
    setButtonHovered: func.isRequired,
    toggleChatWindow: func.isRequired,
  };
}

export default NameScreen;
