import PropTypes from 'prop-types';
import React, { Component } from 'react';
import autobind from 'class-autobind';
import uuid from 'uuid';
import attempt from 'lodash/attempt';
import parse from 'html-react-parser';
import chatApi from 'lib/api/index';
import NameScreen from '../../components/name';
import PrivacyScreen from '../../components/privacy';
import InputArea from '../../components/inputArea';
import ChatHeader from '../../components/chatHeader';
import LoaderComponent from '../../components/loader';

function parseLodash(str) {
  // return an error object rather than let invalid json from breaking app
  return attempt(JSON.parse.bind(null, str));
}

class ChatContainer extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.chatFieldRef = React.createRef();
    this.state = {
      buttonHoverStyle: {},
      chatEndUser: {},
      currentTextField: '',
      currentTextFieldPot: '',
      loader: false, // bool to show or not show loader in conversations
      nameError: '',
      nameField: '',
      nameMessage: 'Please enter your first name',
      nameSupportText: '',
      showName: false, // bool for showing name screen
      showNotification: false, // bool for showing private confirm screen
      userId: '',
      enableSubmit: false,
    };
  }

  componentWillMount() {
    this.detectPrivateBrowsing();
    // prevent bots from constantly resubmitting form
    setTimeout(this.enableSubmit, 3000);
  }

  componentDidMount() {
    this.scrollContent();
  }

  componentDidUpdate(prevProps) {
    const {
      backgroundColor,
    } = this.props;
    // needed for the button hover style
    if (prevProps.backgroundColor !== backgroundColor) {
      this.setButtonHovered(false);
    }
  }

  onChangeEvent(e) {
    e.preventDefault();
    const encUri = encodeURI(e.target.value);
    if (
      encUri === '%0A'
    ) {
      return;
    }

    this.setState({
      currentTextField: e.target.value,
    });
  }

  onPotChangeEvent(e) {
    e.preventDefault();
    this.setState({
      currentTextFieldPot: e.target.value,
    });
  }

  onNameChangeEvent(e) {
    e.preventDefault();
    if (encodeURI(e.target.value) === '%0A') {
      return;
    }
    if (e.key !== 'Enter') {
      this.setState({
        nameField: e.target.value,
      });
    }
  }

  getWelcomeMessage() {
    const {
      nameField,
      userId,
    } = this.state;
    const {
      environmentId,
      projectId,
    } = this.props;
    const postObj = {
      projectId,
      query: 'get started',
      source: 'iqchat',
      environmentId,
      name: nameField,
      userId,
    };
    this.setState({
      loader: true,
    }, () => {
      chatApi.postItem(postObj).then(this.addResponse);
      this.scrollContent();
    });
  }

  setButtonHovered(v) {
    /* func to add customized styles for hover state of button */
    const {
      buttonColor,
      backgroundColor,
    } = this.props;
    if (v) {
      this.setState({
        buttonHoverStyle: buttonColor,
      });
    } else {
      this.setState({
        buttonHoverStyle: backgroundColor,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getTodaysDate() {
    const d = new Date();
    const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    return currentDate;
  }

  enableSubmit() {
    this.setState({
      enableSubmit: true,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  scrollContent() {
    // need this func to make sure the content scrolls on page load.
    const chatWin = document.getElementsByClassName('kubra-chat-conversation')[0];
    if (chatWin) {
      chatWin.scrollTop = chatWin.scrollHeight - chatWin.clientHeight;
    }
  }

  detectChecks() {
    this.detectUserId();
    this.detectName();
  }

  detectPrivateBrowsing() {
    const ls = window.localStorage.getItem('useLocal');
    const userIdSS = window.sessionStorage.getItem('userid');

    // no user info in local or seesion storage
    if (
      window.localStorage.getItem('userid') === null
      && userIdSS === null
    ) {
      // if we are supposed to show the private panel
      if (this.props.trustedDevice === true) {
        // if there is nothing in local storage
        if (ls === null) {
          // show the privacy notification and make sure no name panel is shown
          this.setButtonHovered(false);
          this.setState({
            showNotification: true,
            showName: false,
          });
        } else if (userIdSS !== null) {
          // if there is a user id in session storage go to name check
          this.setState({
            showNotification: false,
          }, () => {
            window.localStorage.removeItem('useLocal');
            this.detectChecks();
          });
        }
      } else {
        // if we are not supposed to show the privacy panel then we do this
        this.detectChecks();
      }
    } else {
      this.detectChecks();
    }
  }

  detectName() {
    const wLs = window.localStorage;
    const wSs = window.sessionStorage;
    const getNameLocal = wLs.getItem('nameField');
    const getNameSession = wSs.getItem('nameField');

    // if we dont have name feature enabled then go to chat
    if (!this.props.nameEnabled) {
      this.setState({
        showName: false,
      }, () => {
        if (
          wLs.getItem('chatHistory') !== null
          || wSs.getItem('chatHistory') !== null
        ) {
          this.populateHistory();
        } else {
          // reset chat history if user comes through and the date doesnt match
          this.getWelcomeMessage();
        }
      });
      return;
    }

    // if we have name enabled then set it up
    if (getNameLocal !== null) {
      this.setState({
        nameField: getNameLocal,
        showName: false,
      }, () => {
        this.populateHistory();
      });
    } else if (getNameSession !== null) {
      this.setState({
        nameField: getNameSession,
        showName: false,
      }, () => {
        this.populateHistory();
      });
    } else {
      this.setState({
        showName: true,
      });
    }
  }

  detectUserId() {
    // check if we are using local storage
    const uniqueid = uuid.v4().substring(0, 34);
    if (window.localStorage.getItem('useLocal')) {
      if (window.localStorage.getItem('userid') === null) {
        window.localStorage.setItem('userid', uniqueid);
      }
      this.setState({
        userId: window.localStorage.getItem('userid'),
      });
    } else {
      if (window.sessionStorage.getItem('userid') === null) {
        window.sessionStorage.setItem('userid', uniqueid);
      }
      this.setState({
        userId: window.sessionStorage.getItem('userid'),
      });
    }
  }

  populateHistory() {
    const ls = window.localStorage;
    let history;
    if (ls.getItem('chatHistory') !== null) {
      history = ls.getItem('chatHistory');
    } else {
      history = window.sessionStorage.getItem('chatHistory');
    }

    if (history !== null) {
      this.setState({
        chatEndUser: parseLodash(history),
      }, () => {
        setTimeout(this.scrollContent, 200);
      });
    }
  }

  addResponse(r) {
    let adjustedResponse = r;
    const errorMsg = 'There has been an error. Please try your request again later';
    if (r === undefined) {
      adjustedResponse = errorMsg;
    } else {
      adjustedResponse = r.fulfillmentText;

      if (r.fulfillmentText === null || r.fulfillmentText === undefined) {
        adjustedResponse = errorMsg;
      }
    }

    this.setState(prevState => ({
      loader: false,
      chatEndUser: {
        ...prevState.chatEndUser,
        [uuid.v4()]: {
          dir: 'in',
          text: adjustedResponse,
        },
      },
    }), () => {
      this.scrollContent();
      if (window.localStorage.getItem('useLocal')) {
        window.localStorage.setItem('chatHistory', JSON.stringify(this.state.chatEndUser));
      } else {
        window.sessionStorage.setItem('chatHistory', JSON.stringify(this.state.chatEndUser));
      }
      this.focusOnInput();
    });
  }

  sendMessage() {
    const {
      currentTextField,
    } = this.state;
    const e = {
      key: 'Enter',
      target: {
        value: currentTextField,
      },
    };
    if (currentTextField !== '') {
      this.keyPressEvent(e);
    }
  }

  parseTextToLink(inputText) {
    // URLs starting with http://, https://, or ftp://
    // eslint-disable-next-line no-useless-escape
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    this.replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with www. (without // before it, or it'd re-link the ones done above)
    // eslint-disable-next-line no-useless-escape
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    this.replacedText = this.replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links
    const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    this.replacedText = this.replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return this.replacedText;
  }

  nameKeyPressEvent(e) {
    const newText = e.target.value;
    if (newText !== '') {
      this.setState(() => ({
        nameField: newText,
      }), () => {
        this.validateNameEntry(newText);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  containsSpecialCharacters(str) {
    // eslint-disable-next-line no-useless-escape
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    return regex.test(str);
  }

  validateNameEntry(v) {
    const sc = this.containsSpecialCharacters(v);
    if (v === '' || sc) {
      this.setState({
        nameError: 'Please enter a valid name',
      });
      return false;
    }
    this.setState({
      nameError: '',
    });
    return true;
  }

  keyPressEvent(e) {
    // if honeypot field is filled out then dont let them submit the form
    if (
      this.state.currentTextFieldPot.length > 0
      || this.state.enableSubmit === false
    ) {
      return;
    }

    const {
      chatEndUser,
      nameField,
      userId,
    } = this.state;
    const {
      environmentId,
      projectId,
    } = this.props;

    const newText = this.parseTextToLink(e.target.value);
    if (e.key === 'Enter' && newText !== '') {
      const postObj = {
        projectId,
        query: newText,
        source: 'iqchat',
        environmentId,
        nameField,
        userId,
      };
      this.setState({
        loader: true,
        chatEndUser: {
          ...chatEndUser,
          [uuid.v4()]: {
            dir: 'out',
            text: newText,
          },
        },
        currentTextField: '',
      }, () => {
        chatApi.postItem(postObj).then(this.addResponse);
        this.scrollContent();
      });
    }
  }

  focusOnInput() {
    if (this.chatFieldRef.current) {
      this.chatFieldRef.current.focus();
    }
  }

  toggleScroll(e) {
    const {
      chatWinMin,
    } = this.props;

    if (e.target.className.includes('chatToggle') || (e.target.className.includes('kubra-chat-header') && chatWinMin)) {
      e.stopPropagation();
      setTimeout(() => {
        this.scrollContent();
      }, 500);
      this.props.toggleWinMin();
    }
  }

  localStorageCheck(get, set, v) {
    if (window.localStorage.getItem(get)) {
      window.localStorage.setItem(set, v);
      this.populateHistory();
    } else {
      window.sessionStorage.setItem(set, v);
    }
  }

  nameChatClicked() {
    if (this.validateNameEntry(this.state.nameField)) {
      this.localStorageCheck('useLocal', 'nameField', this.state.nameField);
      this.detectUserId();
      this.setState({
        showName: false,
      }, () => {
        this.getWelcomeMessage();
      });
    }
  }

  privateConfirmClicked(v) {
    const ls = window.localStorage;
    const ss = window.sessionStorage;

    this.setState({
      showNotification: false,
    }, () => {
      if (v) {
        ls.setItem('useLocal', v);
        ss.removeItem('chatHistory');
        ss.removeItem('userid');
      } else {
        ls.removeItem('useLocal');
      }
      this.detectChecks();
    });
  }

  render() {
    const {
      buttonHoverStyle,
      chatEndUser,
      currentTextField,
      currentTextFieldPot,
      loader,
      nameError,
      nameField,
      nameMessage,
      nameSupportText,
      showName,
      showNotification,
    } = this.state;

    const {
      backgroundColor,
      chatBubbleIn,
      chatBubbleOut,
      chatWinMin,
      chatWindowOpen,
      privacyMessage,
      privacySupportText,
      title,
      toggleChatWindow,
    } = this.props;
    return (
      <div>
        { chatWindowOpen
        && (
        <div
          className={`kubra-chat-window ${chatWinMin ? 'short' : ''}`}
        >
          <ChatHeader
            backgroundColor={backgroundColor}
            chatWinMin={chatWinMin}
            title={title}
            toggleChatWindow={toggleChatWindow}
            toggleScroll={this.toggleScroll}
          />
          { !chatWinMin
          && (
          <div className="kubra-chat-body">
            <div className="kubra-chat-conversation">
              { showNotification
                && (
                <PrivacyScreen
                  buttonHoverStyle={buttonHoverStyle}
                  privateConfirmClicked={this.privateConfirmClicked}
                  privacyMessage={privacyMessage}
                  privacySupportText={privacySupportText}
                  setButtonHovered={this.setButtonHovered}
                />
                )}
              { showName
                && (
                <NameScreen
                  buttonHoverStyle={buttonHoverStyle}
                  nameError={nameError}
                  nameField={nameField}
                  nameKeyPressEvent={this.nameKeyPressEvent}
                  nameMessage={nameMessage}
                  nameSupportText={nameSupportText}
                  nameChatClicked={this.nameChatClicked}
                  onNameChangeEvent={this.onNameChangeEvent}
                  setButtonHovered={this.setButtonHovered}
                  toggleChatWindow={this.props.toggleChatWindow}
                />
                )}
              <div className="chat-holder">
                {Object.keys(chatEndUser).map(chats => (
                  <div className={`chat-bubble ${chatEndUser[chats].dir === 'in' ? 'iq' : 'user'}`} key={uuid.v4()} style={chatEndUser[chats].dir === 'in' ? chatBubbleIn : chatBubbleOut}>
                    {chatEndUser[chats].text
                      && (
                        parse(chatEndUser[chats].text)
                      )
                    }
                  </div>
                ))}
              </div>
              <LoaderComponent
                loader={loader}
              />
            </div>
            { (!showNotification && !showName)
              && (
                <InputArea
                  backgroundStyle={backgroundColor}
                  chatRef={this.chatFieldRef}
                  fieldValue={currentTextField}
                  fieldValuePot={currentTextFieldPot}
                  inputChangePot={this.onPotChangeEvent}
                  inputChange={this.onChangeEvent}
                  keyPressChange={this.keyPressEvent}
                  send={this.sendMessage}
                />
              )}
          </div>
          )}
        </div>
        )}
      </div>
    );
  }
}


ChatContainer.defaultProps = {
  environmentId: null,
};

{
  const {
    string, shape, bool, func,
  } = PropTypes;

  ChatContainer.propTypes = {
    backgroundColor: shape({}).isRequired,
    buttonColor: shape({}).isRequired,
    chatBubbleIn: shape({}).isRequired,
    chatBubbleOut: shape({}).isRequired,
    chatWinMin: bool.isRequired,
    chatWindowOpen: bool.isRequired,
    environmentId: string,
    nameEnabled: bool.isRequired,
    projectId: string.isRequired,
    privacyMessage: string.isRequired,
    privacySupportText: string.isRequired,
    title: string.isRequired,
    toggleChatWindow: func.isRequired,
    toggleWinMin: func.isRequired,
    trustedDevice: bool.isRequired,
  };
}

export default ChatContainer;
