/* eslint-disable */
import React, { Component } from 'react';
import autobind from 'class-autobind';
import ChatContainer from './modules/Chat/ChatContainer';
import ChatCTA from './modules/Chat/ChatCTA';

function getBool(val) {
  return !!JSON.parse(String(val).toLowerCase());
}

export class App extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.state = {
      backgroundStyle: {}, // background color for chat title and send button
      buttonStyle: {},
      chatBubbleIn: {}, // style for chat bubble from Chat bot response
      chatBubbleOut: {}, // style for chat bubble from user input
      chatWinMin: false, // minimize chat window
      chatWindowOpen: false, // bool for showing button or chat win
      ctaPosition: 'left', // position for chat button
      ctaTitle: 'Chat', // title for chat button
      nameEnabled: false, // flag for showing name field
      privacyMessage: 'Are you using a trusted device?', // message for privacy title
      privacySupportText: 'A trusted device is a device you use frequently, such as your personal mobile phone, tablet or home computer',
      stylePosition: {}, // obj for if you customize the position of the chat cta
      trustedDevicePanel: true, // show trusted device panel
      widgetTitle: 'Chat', // title for chat win
    };
  }

  componentWillMount() {
    this.checkPrivateDevice();
    this.checkNameEnabled();
    this.checkWindowVisibility();
    this.getWidgetId();
    this.getHeaderTitle();
    this.getWidgetColor();
    this.getWidgetPrivacyMessage();
    this.getWidgetChatBubbles();
    this.checkWinMin();
    this.getCTAPosition();
  }

  getCTAPosition() {
    let _v;
    if(kubraChatWidget.banner) {
      const _pos = kubraChatWidget.banner.position;
      if (_pos) {
          _v = _pos;
      }
    }

    // if there is no custom override then default to left
    if(_v !== undefined) {
      if(typeof _v === 'string') {
        this.setState({
          ctaPosition: _v,
        });
      // sending custom object to position
      } else if (typeof _v === 'object') {
        this.setState({
          stylePosition: _v,
        });
      }
    }
  }

  getHeaderTitle() {
    let _cta;
    let _wTitle;

    if (kubraChatWidget.chatTitle) {
      _wTitle = kubraChatWidget.chatTitle;
    }
    if (kubraChatWidget.banner) {
      if (kubraChatWidget.banner.text) {
        _cta = kubraChatWidget.banner.text;
      }
    }

    if(_cta === undefined) {
      _cta='Chat';
    }
    if(_wTitle === undefined) {
      _wTitle='Chat';
    }

    this.setState({
      widgetTitle: _wTitle,
      ctaTitle: _cta,
    });
  }

  getWidgetId() {
    let _id = 'NoBotConfigured';
    let _env = null;
    if (kubraChatWidget.id) {
      _id = kubraChatWidget.id;
    }
    if (kubraChatWidget.env) {
        _env = kubraChatWidget.env;
    }
    this.setState({
      projectId: _id,
      env: _env,
    })

  }

  getWidgetColor() {
    let _c;
    
    if(kubraChatWidget.colors) {
      if(kubraChatWidget.colors.base) {
        _c = kubraChatWidget.colors.base;
      }
    }

    this.setState(prevState => ({
      backgroundStyle: {
        ...prevState.backgroundStyle,
        backgroundColor: _c,
      },
      buttonStyle: {
        ...prevState.buttonStyle,
        color: _c,
        borderColor: _c,
      },
    }), () => {
      this.getWidgetTextColor();
    });
  }

  getWidgetTextColor() {
    if (kubraChatWidget.colors) {
      if (kubraChatWidget.colors.text) {
        this.setState(prevState => ({
          backgroundStyle: {
            ...prevState.backgroundStyle,
            color: kubraChatWidget.colors.text,
          },
        }));
      }
    }
  }

  getWidgetPrivacyMessage() {
    const { privacyMessage, privacySupportText } = this.state;
    let _message = privacyMessage;
    let _sText = privacySupportText;

    if (kubraChatWidget.privacy) {
      if (kubraChatWidget.privacy.title) {
        _message = kubraChatWidget.privacy.title;
      }
      if (kubraChatWidget.privacy.supportText) {
        _sText = kubraChatWidget.privacy.supportText;
      }
    }

    this.setState({
      privacySupportText: _sText,
      privacyMessage: _message,
    });
  }

  getWidgetChatBubbles() {
    let _r;
    let _i;
    if(kubraChatWidget.bubbles) {
      if(kubraChatWidget.bubbles.response) {
        _r = kubraChatWidget.bubbles.response;
      }
      if(kubraChatWidget.bubbles.response) {
        _i = kubraChatWidget.bubbles.input;
      }
    }

    this.setState({
      chatBubbleOut: {
        background: _i,
      },
      chatBubbleIn: {
        background: _r,
      },
    }, () => {
      this.getWidgetChatBubblesText();
    });
    
  }

  getWidgetChatBubblesText() {
    /* Pull custom chat bubble colors from meta */
    const {
      chatBubbleOut,
      chatBubbleIn,
    } = this.state;
    let _r = {};
    let _i = {};

    if(kubraChatWidget.bubbles) {
      if(kubraChatWidget.bubbles.responseText) {
        _r = kubraChatWidget.bubbles.responseText;
      }
      if(kubraChatWidget.bubbles.inputText) {
        _i = kubraChatWidget.bubbles.inputText;
      }
    }

    this.setState({
      chatBubbleOut: {
        ...chatBubbleOut,
        color: _i,
      },
      chatBubbleIn: {
        ...chatBubbleIn,
        color: _r,
      },
    });
  }

  checkWindowVisibility() {
    const sessionOpen = window.sessionStorage.getItem('chatWindowOpen');

    if (sessionOpen !== null) {
      this.setState({
        chatWindowOpen: getBool(sessionOpen),
      });
    }
  }

  checkWinMin() {
    const open = window.sessionStorage.getItem('chatWinMin');

    if (open !== null) {
      this.setState({
        chatWinMin: getBool(open),
      });
    }
  }

  checkPrivateDevice() {
    let _tdPanel = true;

    if(kubraChatWidget.privacy) {
      if (kubraChatWidget.privacy.enabled) {
        _tdPanel = kubraChatWidget.privacy.enabled;
      }
    }

    this.setState({
      trustedDevicePanel: _tdPanel,
    });

  }

  checkNameEnabled() {
    let _name = false;
    if (kubraChatWidget.name) {
      if(kubraChatWidget.name.enabled) {
        _name = kubraChatWidget.name.enabled;
      }
    }

    this.setState({
      nameEnabled: _name,
    });
  }

  toggleWinMin() {
    this.setState(prevState => ({
      chatWinMin: !prevState.chatWinMin,
    }), () => {
      window.sessionStorage.setItem('chatWinMin', this.state.chatWinMin);
    });
  }

  toggleChatWindow() {
    this.setState(prevState => ({
      chatWindowOpen: !prevState.chatWindowOpen,
    }), () => {
      window.sessionStorage.setItem('chatWindowOpen', this.state.chatWindowOpen);
    });
  }

  render() {
    const {
      backgroundStyle,
      buttonStyle,
      chatBubbleIn,
      chatBubbleOut,
      chatWindowOpen,
      chatWinMin,
      ctaTitle,
      ctaPosition,
      env,
      nameEnabled,
      privacyMessage,
      privacySupportText,
      projectId,
      stylePosition,
      trustedDevicePanel,
      widgetTitle,
    } = this.state;
    return (
      <div className="app">
        <ChatCTA
          backgroundColor={backgroundStyle}
          chatWindowOpen={chatWindowOpen}
          ctaPosition={ctaPosition}
          stylePosition={stylePosition}
          title={ctaTitle}
          toggleChatWindow={this.toggleChatWindow}
        />
        <ChatContainer
          backgroundColor={backgroundStyle}
          buttonColor={buttonStyle}
          chatBubbleIn={chatBubbleIn}
          chatBubbleOut={chatBubbleOut}
          chatWinMin={chatWinMin}
          chatWindowOpen={chatWindowOpen}
          environmentId={env}
          nameEnabled={nameEnabled}
          projectId={projectId}
          privacyMessage={privacyMessage}
          privacySupportText={privacySupportText}
          title={widgetTitle}
          toggleChatWindow={this.toggleChatWindow}
          toggleWinMin={this.toggleWinMin}
          trustedDevice={trustedDevicePanel}
        />
      </div>
    );
  }
}

export default App;
