import _ from 'lodash';
import config from './default';
import origin from '../lib/origin';

const BASE_URL = origin();

const qa = _.cloneDeep(config);

const authConfig = {
  ...qa.auth,
  silentCallbackPath: `${BASE_URL}/boilerplate/silent-callback`,
  callbackPath: `${BASE_URL}/boilerplate/callback`,
  logoutPath: `${BASE_URL}/boilerplate/logout`,
};

qa.auth = authConfig;

qa.api.chatApi.host = 'https://qa.kubra.io/iq/v1';

export default qa;
