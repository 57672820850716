import _ from 'lodash';
import config from './default';

const prod = _.cloneDeep(config);

prod.googleAnalyticsCode = 'UA-110840836-1';

const authConfig = {
  ...prod.auth,
  clientId: 'PcggvMMnn7EzE9POSiz9CN4XtibvBfD2',
  origin: 'auth0.kubra.io',
  audience: 'https://kubra.io/api',
  responseType: 'token id_token',
  scope: 'openid profile name email',
};

prod.auth = authConfig;

prod.api.chatApi.host = 'https://kubra.io/iq/v1';

export default prod;
